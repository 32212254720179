/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { useUpdateAtom } from 'jotai/utils'

import Button from '../button'
import Card from '../card'
import CardType from '../card-type'
import ImageFill, { RatioBox } from '../image'
import { activeMemberStoryAtom } from '../member-story-overlay'

import { renderHTML } from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty, VStack } from '../../styles/els'
import { fillArea } from '../../styles/css'
import { useThemeUISetValue } from '../../styles/utils'

const CardSocial = (props) => {
  const { text, image, url, handle, icon, ...otherProps } = props

  const { theme } = useThemeUI()
  const padding = useThemeUISetValue('cardPadding', theme)

  return (
    <Card
      sx={{
        position: 'relative',
        p: 0,
      }}
    >
      <RatioBox
        ratio={1}
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      />
      {image ? (
        <div
          sx={{
            zIndex: 1,
          }}
          css={css`
            ${fillArea}
          `}
        >
          <div
            sx={{
              zIndex: 2,
              backgroundColor: 'grey4',
              opacity: 0.3,
            }}
            css={css`
              ${fillArea}
            `}
          />
          <ImageFill image={image} />
        </div>
      ) : null}
      {text ? (
        <div
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            zIndex: 3,
            px: padding,
            pt: padding,
            pb: 9,
          }}
        >
          <div sx={{ variant: 'text.regular_25' }}>{renderHTML(text)}</div>
        </div>
      ) : null}
      <div
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 5,
          pl: [4, 5],
          pb: [4, 5],
        }}
      >
        <Button
          to={url}
          label={`@${handle}`}
          display="secondary"
          type="external"
          icon={icon}
        />
      </div>
    </Card>
  )
}

export default CardSocial
