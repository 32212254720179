/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter } from 'lodash'

import CardSection from './index'
import { mapProjectToCard } from '../whats-on-section'

const query = graphql`
  query {
    projects: allWpProject(
      limit: 6
      sort: { order: ASC, fields: menuOrder }
      filter: { acfProject: { isCurrent: { eq: true } } }
    ) {
      nodes {
        title
        uri
        id
        acfProject {
          header {
            image {
              ...InlineImage
            }
          }
          relatedPage {
            ... on WpPage {
              id
              title
            }
          }
        }
      }
    }
    whatsOnPage: allWpPage(
      filter: { template: { templateName: { eq: "What's On" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

const ProjectCardSection = (props) => {
  const { heading, relatedPage, ...otherProps } = props
  const data = useStaticQuery(query)
  const projects = useMemo(() => {
    const _nodes = get(data, 'projects.nodes')
    const _p = relatedPage
      ? filter(_nodes, (_o) => {
          return get(_o, 'acfProject.relatedPage.0.id') === relatedPage
        })
      : _nodes
    if (_p) {
      _p.length = Math.min(_p.length, 3)
    }
    return _p ? _p.map((_p) => mapProjectToCard(_p)) : null
  }, [data])
  const whatsOnPage = useMemo(() => {
    return get(data, 'whatsOnPage.nodes.0')
  }, [data])

  return projects.length > 0 ? (
    <CardSection
      heading={heading ? heading : 'On now'}
      cards={projects}
      sectionLink={whatsOnPage ? { to: whatsOnPage.uri } : null}
      sectionLinkLabel="Learn more"
      {...otherProps}
    />
  ) : null
}

export default ProjectCardSection
