/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { DateTime } from 'luxon'

import CardVertical from '../card-vertical'
import CardSection, { CardTypes } from '../card-section'
import Section from '../section'

import { renderHTML, formatDate, formatImage } from '../../lib/helpers'
import { InlineImage } from '../../lib/fragments'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'

const query = graphql`
  query {
    news: allWpNewsarticle(sort: { order: DESC, fields: date }) {
      nodes {
        title
        uri
        date
        acfNews {
          header {
            image {
              ...InlineImage
            }
          }
          intro
        }
      }
    }
  }
`

export const mapNewsToCard = (news, opts = {}) => {
  const date = DateTime.fromISO(get(news, 'date'))
  const sub = date ? date.toFormat('LLLL yyyy') : null
  const title = get(news, 'title')
  const link = {
    to: get(news, 'uri'),
  }
  const image = formatImage(get(news, 'acfNews.header.image'))

  return {
    sub,
    heading: title,
    image,
    link,
    buttonLabel: 'Read more',
    type: CardTypes.NEWS,
  }
}

const News = (props) => {
  const data = useStaticQuery(query)
  const news = useMemo(() => {
    return get(data, 'news.nodes').map((_p) => mapNewsToCard(_p))
  }, [data])

  return <CardSection cards={news} colBase={3} />
}

export default News
