/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'

import CardVertical from '../card-vertical'
import CardSection from '../card-section'
import Section from '../section'

import { renderHTML, formatDate, formatImage } from '../../lib/helpers'
import { InlineImage } from '../../lib/fragments'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'

const query = graphql`
  query {
    allWpProduction(
      sort: {
        order: [DESC, ASC]
        fields: [acfProduction___dateStart, menuOrder]
      }
      filter: { acfProduction: { isCurrent: { ne: true } } }
    ) {
      nodes {
        title
        uri
        acfProduction {
          dateStart
          dateEnd
          image {
            ...InlineImage
          }
        }
      }
    }
  }
`

export const mapProductionToCard = (production, opts = {}) => {
  const { includeText } = opts

  const isCurrent = get(production, 'acfProduction.isCurrent')

  const dateStart = get(production, 'acfProduction.dateStart')
  const dateEnd = get(production, 'acfProduction.dateEnd')
  const sub = isCurrent
    ? get(production, 'acfProduction.whatsOnDate')
    : formatDate(dateStart, dateEnd)

  const title = get(production, 'title')
  const link = {
    to: get(production, 'uri'),
  }
  const text = includeText
    ? isCurrent
      ? get(production, 'acfProduction.whatsOnDescription')
      : get(production, 'acfProduction.intro')
    : null

  const image = formatImage(get(production, 'acfProduction.image'))

  const buttonColor = get(production, 'acfProduction.pageColour')

  return {
    sub,
    heading: title,
    text,
    image,
    link,
    buttonColor,
  }
}

const PastProductions = (props) => {
  const data = useStaticQuery(query)
  const productions = useMemo(() => {
    return get(data, 'allWpProduction.nodes').map((_p) =>
      mapProductionToCard(_p)
    )
  }, [data])

  return <CardSection cards={productions} />
}

export default PastProductions
