/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter, isFinite } from 'lodash'

import CardSection from './index'
import { mapVideoToCard } from '../videos'

import { formatImage } from '../../lib/helpers'
import { InlineImage } from '../../lib/fragments'

const query = graphql`
  query {
    videos: allWpVideo(limit: 5, sort: { order: ASC, fields: menuOrder }) {
      nodes {
        title
        uri
        id
        acfVideo {
          thumbnail {
            ...FullsizeImage
          }
        }
      }
    }
    videosPage: allWpPage(
      filter: { template: { templateName: { eq: "Synergy At Home" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

const Video = (props) => {
  const { heading, toFilter, count, ...otherProps } = props
  const data = useStaticQuery(query)
  const videos = useMemo(() => {
    const _v = filter(get(data, 'videos.nodes'), (_o) => {
      return _o.id !== toFilter
    })
    if (_v) {
      _v.length = Math.min(_v.length, isFinite(count) ? count : 3)
    }
    return _v ? _v.map((_v) => mapVideoToCard(_v)) : null
  }, [data, count])
  const videosPage = useMemo(() => {
    return get(data, 'videosPage.nodes.0')
  }, [data])

  return (
    <CardSection
      heading={heading ? heading : 'Videos'}
      cards={videos}
      sectionLink={videosPage ? { to: videosPage.uri } : null}
      sectionLinkLabel="Watch more"
      {...otherProps}
    />
  )
}

export default Video
