/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter, over } from 'lodash'

import { mapProductionToCard } from '../past-productions'

import { formatImage } from '../../lib/helpers'

import CardSection, { CardTypes } from './index'

const query = graphql`
  query {
    stories: allWpStory(limit: 3, sort: { order: ASC, fields: menuOrder }) {
      nodes {
        title
        uri
        id
        acfStory {
          thumbnail {
            ...InlineImage
          }
        }
      }
    }
  }
`

export const mapStoryToCard = (_s) => {
  const image = get(_s, 'acfStory.thumbnail')
  return {
    heading: _s.title,
    id: _s.id,
    image: image ? formatImage(image) : null,
    type: CardTypes.STORY,
  }
}

const MemberStoriesCardSection = (props) => {
  const { heading, toFilter, overwriteCards, otherProps } = props
  const data = useStaticQuery(query)
  const stories = useMemo(() => {
    const _stories = get(data, 'stories.nodes')
    let storyCards = []

    if (overwriteCards && overwriteCards.length > 0) {
      for (let i = 0; i < overwriteCards.length; i++) {
        const _overwriteCard = get(overwriteCards, `${i}.card.0`)
        if (_overwriteCard) {
          storyCards.push(mapStoryToCard(_overwriteCard))
        }
      }
    }

    for (let i = 0; i < _stories.length; i++) {
      if (storyCards.length >= 3) {
        break
      }
      storyCards.push(mapStoryToCard(_stories[i]))
    }
    return storyCards
  }, [data, overwriteCards])

  return (
    <CardSection
      heading={heading ? heading : 'Member stories'}
      cards={stories}
      {...otherProps}
    />
  )
}

export default MemberStoriesCardSection
