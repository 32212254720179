/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useMemo } from 'react'
import { get, isFunction } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import CardHorizontal from '../card-horizontal'
import Introduction from '../introduction'
import { FullHeader } from '../page-header'
import { PastProductionsCardSection, VideosCardSection } from '../card-section'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  VStack,
} from '../../styles/els'

import { formatImage } from '../../lib/helpers'
import Section from '../section'
import { mapProductionToCard } from '../past-productions'
import CardRow from '../card-row'
import { useThemeUISetValue } from '../../styles/utils'

export const query = graphql`
  query {
    productions: allWpProduction(
      filter: { acfProduction: { isCurrent: { eq: true } } }
      sort: { order: DESC, fields: acfProduction___dateStart }
    ) {
      nodes {
        title
        uri
        acfProduction {
          pageColour
          dateStart
          dateEnd
          image {
            ...InlineImage
          }
          isCurrent
          whatsOnDate
          whatsOnDescription
        }
      }
    }
    projects: allWpProject(
      filter: { acfProject: { isCurrent: { eq: true } } }
    ) {
      nodes {
        title
        uri
        acfProject {
          header {
            image {
              ...InlineImage
            }
          }
          relatedPage {
            ... on WpPage {
              title
            }
          }
        }
      }
    }
  }
`

export const mapProjectToCard = (project) => {
  const sub = get(project, 'acfProject.relatedPage.0.title')
  const title = get(project, 'title')
  const link = get(project, 'uri')
  const image = formatImage(get(project, 'acfProject.header.image'))

  return {
    sub: sub,
    heading: title,
    link: link
      ? {
          to: link,
        }
      : null,
    image,
  }
}

const WhatsOn = (props) => {
  const { hideHeading } = props
  const data = useStaticQuery(query)

  const { theme } = useThemeUI()
  const productions = useMemo(() => {
    const p = get(data, 'productions.nodes')
    return p && p.length > 0
      ? p.map((_p, _i) => {
          return mapProductionToCard(_p, { includeText: true })
        })
      : null
  }, [data])
  const projects = useMemo(() => {
    const p = get(data, 'projects.nodes')
    return p && p.length > 0
      ? p.map((_p, _i) => {
          return mapProjectToCard(_p)
        })
      : null
  }, [data])
  const vSpace = useThemeUISetValue('gridSpace', theme)

  return (
    <Section>
      {!hideHeading ? (
        <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>What's on</h3>
      ) : null}
      {productions && productions.length > 0 ? (
        <VStack
          sx={{
            pb: projects && projects.length > 0 ? vSpace : null,
          }}
        >
          {productions.map((_production, _i) => {
            return <CardHorizontal key={_i} {..._production} featured={true} />
          })}
        </VStack>
      ) : null}
      <CardRow cards={projects} />
    </Section>
  )
}

export default WhatsOn
