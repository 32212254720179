/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../button'
import CardType from '../card-type'
import ImageFill, { RatioBox } from '../image'

import { renderHTML } from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'

const CardHorizontal = (props) => {
  const {
    heading,
    text,
    buttonLabel,
    buttonColor,
    image,
    link,
    greyBg,
    featured,
    ...otherProps
  } = props

  const { theme } = useThemeUI()
  const padding = useThemeUISetValue('cardPadding', theme)

  const splitBase = featured ? 7 : 2
  const leftSplit = featured ? 2 : 1
  const rightSplit = splitBase - leftSplit

  return (
    <div
      sx={{
        display: 'flex',
        flexWrap: ['wrap-reverse', null, null, 'nowrap'],
        alignItems: 'stretch',
        backgroundColor: greyBg ? 'black' : 'grey4',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      <div
        sx={{
          p: padding,
          flex: ['none', null, null, 1, leftSplit],
          width: [
            '100%',
            null,
            null,
            '50%',
            `${(leftSplit / splitBase) * 100}%`,
          ],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'space-between',
        }}
      >
        <CardType tier={featured ? 0 : 1} {...props} />
        {link ? (
          <div
            sx={{
              pt: 7,
            }}
          >
            <Button
              {...link}
              display="primary"
              label={buttonLabel ? buttonLabel : 'Learn more'}
              color={buttonColor}
            />
          </div>
        ) : null}
      </div>
      <div
        sx={{
          position: 'relative',
          flex: ['none', null, null, 1, rightSplit],
          width: [
            '100%',
            null,
            null,
            '50%',
            `${(rightSplit / splitBase) * 100}%`,
          ],
          backgroundColor: greyBg ? 'grey3' : 'grey2',
        }}
      >
        <RatioBox ratio={featured ? 420 / 640 : 380 / 640} />
        {image ? <ImageFill image={image} /> : null}
      </div>
    </div>
  )
}

export default CardHorizontal
