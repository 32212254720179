/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get, isArray, isFinite } from 'lodash'

import CardStory from '../card-story'
import CardVertical from '../card-vertical'
import { CardTypes } from '../card-section'
import Section from '../section'

import { renderHTML, formatDate, formatImage } from '../../lib/helpers'
import { FullsizeImage } from '../../lib/fragments'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'
import CardNews from '../card-news'
import CardSocial from '../card-social'

const CardRow = (props) => {
  const {
    cards,
    colBase: _colBase,
    greyBg,
    forceNarrowRatio,
    buttonPrimary,
  } = props

  const cardCount = cards && cards.length > 0 ? cards.length : 0
  const colBase = isArray(_colBase)
    ? _colBase
    : [
        1,
        null,
        null,
        isFinite(_colBase)
          ? _colBase
          : Math.min(
              3,
              Math.max(
                2,
                (cardCount < 6 && cardCount % 2 === 0) || cardCount <= 2 ? 2 : 3
              )
            ),
      ]

  return cardCount > 0 ? (
    <FlexCols
      sx={{
        justifyContent: cardCount === 1 ? 'center' : null,
      }}
    >
      {cards.map((_card, _i) => {
        return (
          <FlexCol base={colBase} key={_i}>
            {_card.type === CardTypes.SOCIAL ? (
              <CardSocial {..._card} />
            ) : _card.type === CardTypes.STORY ? (
              <CardStory {..._card} />
            ) : (
              <CardVertical
                {..._card}
                buttonPrimary={buttonPrimary}
                wideRatio={!forceNarrowRatio && colBase === 2}
                greyBg={greyBg}
              />
            )}
          </FlexCol>
        )
      })}
    </FlexCols>
  ) : null
}

export default CardRow
