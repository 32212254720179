/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter } from 'lodash'

import { mapProductionToCard } from '../past-productions'

import CardSection from './index'

const query = graphql`
  query {
    productions: allWpProduction(
      limit: 4
      sort: { order: DESC, fields: acfProduction___dateStart }
      filter: { acfProduction: { isCurrent: { ne: true } } }
    ) {
      nodes {
        title
        uri
        id
        acfProduction {
          dateStart
          dateEnd
          image {
            ...InlineImage
          }
        }
      }
    }
    pastProductionPage: allWpPage(
      filter: { template: { templateName: { eq: "Past Productions" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

const PastProductionsCardSection = (props) => {
  const { heading, toFilter, ...otherProps } = props
  const data = useStaticQuery(query)
  const productions = useMemo(() => {
    const _p = filter(get(data, 'productions.nodes'), (_o) => {
      return _o.id !== toFilter
    })
    if (_p) {
      _p.length = Math.min(_p.length, 3)
    }
    return _p ? _p.map((_p) => mapProductionToCard(_p)) : null
  }, [data])
  const pastProductionPage = useMemo(() => {
    return get(data, 'pastProductionPage.nodes.0')
  }, [data])

  return (
    <CardSection
      heading={heading ? heading : 'Past productions'}
      cards={productions}
      sectionLink={pastProductionPage ? { to: pastProductionPage.uri } : null}
      sectionLinkLabel="See all"
      {...otherProps}
    />
  )
}

export default PastProductionsCardSection
