/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact } from 'lodash'

import Button from '../button'
import CardRow from '../card-row'
import CenterCol from '../center-col'
import Quote from '../quote'
import Section from '../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveLink,
} from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'

import MemberStoriesCardSection from './member-stories'
import NewsCardSection from './news'
import PastProductionsCardSection from './past-productions'
import ProjectCardSection from './projects'
import SocialCardSection from './social'
import VideosCardSection from './videos'

const CardSection = (props) => {
  const {
    heading,
    sectionLink,
    sectionLinkLabel,
    cards,
    colBase,
    inset,
    ...otherProps
  } = props

  return (
    <Section inset={inset} bg={inset ? 'grey4' : null}>
      {heading || sectionLink ? (
        <div
          sx={{
            display: ['block', null, 'flex'],
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            pb: [5, 4],
          }}
        >
          {heading ? (
            <h3 sx={{ variant: 'text.heading_36', pb: 4 }}>{heading}</h3>
          ) : null}
          {sectionLink ? (
            <Button
              display="secondary"
              sx={{
                mt: [0, null, '6px'],
              }}
              {...sectionLink}
              label={sectionLinkLabel ? sectionLinkLabel : 'Learn more'}
              invert={true}
            />
          ) : null}
        </div>
      ) : null}
      {cards ? (
        <CardRow colBase={colBase} cards={cards} greyBg={!!inset} />
      ) : null}
    </Section>
  )
}

const CardTypes = {
  NEWS: 'news',
  STORY: 'story',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  SOCIAL: 'social',
}

export default CardSection
export {
  CardTypes,
  MemberStoriesCardSection,
  NewsCardSection,
  PastProductionsCardSection,
  ProjectCardSection,
  SocialCardSection,
  VideosCardSection,
}
