/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter } from 'lodash'

import { mapNewsToCard } from '../news'

import CardSection from './index'

const query = graphql`
  query {
    news: allWpNewsarticle(limit: 4, sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        uri
        date
        acfNews {
          header {
            image {
              ...InlineImage
            }
          }
          intro
        }
      }
    }
    newsPage: allWpPage(
      filter: { template: { templateName: { eq: "News" } } }
      limit: 1
    ) {
      nodes {
        title
        uri
      }
    }
  }
`

const NewsCardSection = (props) => {
  const { heading, toFilter, ...otherProps } = props
  const data = useStaticQuery(query)
  const news = useMemo(() => {
    const _p = filter(get(data, 'news.nodes'), (_o) => {
      return _o.id !== toFilter
    })
    if (_p) {
      _p.length = Math.min(_p.length, 3)
    }
    return _p ? _p.map((_p) => mapNewsToCard(_p)) : null
  }, [data])
  const newsPage = useMemo(() => {
    return get(data, 'newsPage.nodes.0')
  }, [data])

  return (
    <CardSection
      heading={heading ? heading : 'News'}
      cards={news}
      sectionLink={newsPage ? { to: newsPage.uri } : null}
      sectionLinkLabel="Browse news archive"
      {...otherProps}
    />
  )
}

export default NewsCardSection
