/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../button'
import CardType from '../card-type'
import ImageFill, { RatioBox } from '../image'

import { renderHTML } from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'
import { useThemeUISetValue } from '../../styles/utils'

const CardVertical = (props) => {
  const {
    sub,
    heading,
    text,
    buttonLabel,
    buttonColor,
    image,
    link,
    wideRatio,
    greyBg,
    buttonPrimary,
    ...otherProps
  } = props

  const { theme } = useThemeUI()
  const cardPadding = useThemeUISetValue('cardPadding', theme)

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: greyBg ? 'black' : 'grey4',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      <RatioBox
        ratio={wideRatio ? 280 / 620 : 280 / 400}
        sx={{ backgroundColor: greyBg ? 'grey3' : 'grey2' }}
      >
        {image ? <ImageFill image={image} /> : null}
      </RatioBox>
      <div
        sx={{
          px: cardPadding,
          pt: 4,
          pb: cardPadding,
        }}
      >
        <CardType
          {...props}
          tier={buttonPrimary ? 1 : 2}
          sx={{ pb: link ? 6 : null }}
        />
        {link ? (
          <div>
            <Button
              {...link}
              display={buttonPrimary ? 'primary' : 'secondary'}
              label={buttonLabel ? buttonLabel : 'Learn more'}
              color={buttonColor}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardVertical
