/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { useUpdateAtom } from 'jotai/utils'

import Button from '../button'
import CardType from '../card-type'
import ImageFill, { RatioBox } from '../image'
import { activeMemberStoryAtom } from '../member-story-overlay'

import { renderHTML } from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty, VStack } from '../../styles/els'
import { fillArea } from '../../styles/css'

const CardStory = (props) => {
  const { heading, image, id, greyBg, ...otherProps } = props
  const setActiveMemberStory = useUpdateAtom(activeMemberStoryAtom)

  return (
    <div
      sx={{
        position: 'relative',
        display: 'flex',
        backgroundColor: greyBg ? 'black' : 'grey4',
        borderRadius: 1,
        overflow: 'hidden',

        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={
        id
          ? (e) => {
              e.preventDefault()
              e.stopPropagation()

              setActiveMemberStory(id)
            }
          : null
      }
    >
      <RatioBox
        ratio={550 / 400}
        sx={{ backgroundColor: greyBg ? 'grey3' : 'grey2' }}
      />
      {image ? <ImageFill image={image} /> : null}
      <div
        css={css`
          ${fillArea}
        `}
        sx={{
          p: 4,
          zIndex: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <VStack
          space={2}
          sx={{
            pb: 2,
            alignItems: 'flex-start',
          }}
        >
          <Button label="Member story" display="secondary" invert={true} />
          {heading ? (
            <Button label={heading} display="secondary" invert={true} />
          ) : null}
        </VStack>
        <Button
          label="Play"
          display="secondary"
          sx={{
            justifySelf: 'end',
          }}
        />
      </div>
    </div>
  )
}

export default CardStory
