/** @jsx jsx */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact, filter } from 'lodash'

import CardSection from './index'

import InstagramImg from './img/instagram_4.jpg'
import YoutubeImg from './img/youtube_1.jpg'

const cards = [
  {
    text: 'Tune into @BBCFrontRow at 19:15 to hear @ricrenton speak about his brand new play a @Synergy_Theatre @painesplough & @LiveTheatre co-production which will be opening on the 10th Nov @LiveTheatre <a href="https://live.org.uk/whats-on/one" target="_blank">https://live.org.uk/whats-on/one</a> Well done and we’ll deserved.',
    handle: 'Synergy_Theatre',
    url: 'https://twitter.com/Synergy_Theatre',
    icon: 'twitter',
    type: 'social',
  },
  {
    image: { src: InstagramImg, width: 1080, height: 1080 },
    handle: 'synergytheatreproject',
    url: 'https://www.instagram.com/synergytheatreproject/',
    icon: 'instagram',
    type: 'social',
  },
  {
    image: { src: YoutubeImg, width: 1000, height: 563 },
    handle: 'synergytheatreproject',
    url: 'https://www.youtube.com/synergytheatreproject',
    icon: 'youtube',
    type: 'social',
  },
]
const SocialCardSection = (props) => {
  const { heading, toFilter, ...otherProps } = props

  return (
    <CardSection
      heading={heading ? heading : 'Connect with us'}
      cards={cards}
      {...otherProps}
    />
  )
}

export default SocialCardSection
