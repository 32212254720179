/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { DateTime } from 'luxon'

import CardVertical from '../card-vertical'
import CardSection, { CardTypes } from '../card-section'
import Section from '../section'

import { renderHTML, formatDate, formatImage } from '../../lib/helpers'
import { InlineImage } from '../../lib/fragments'

import { Cont, FlexCols, FlexCol, VStack } from '../../styles/els'

const query = graphql`
  query {
    videos: allWpVideo(sort: { order: ASC, fields: menuOrder }) {
      nodes {
        title
        uri
        id
        acfVideo {
          thumbnail {
            ...FullsizeImage
          }
        }
      }
    }
  }
`

export const mapVideoToCard = (video) => {
  const title = get(video, 'title')
  const link = {
    to: get(video, 'uri'),
  }

  const image = formatImage(get(video, 'acfVideo.thumbnail'))

  return {
    heading: title,
    image,
    link,
    buttonLabel: 'Watch',
  }
}

const Videos = (props) => {
  const data = useStaticQuery(query)
  const videos = useMemo(() => {
    return get(data, 'videos.nodes').map((_p) => mapVideoToCard(_p))
  }, [data])

  return <CardSection cards={videos} />
}

export default Videos
