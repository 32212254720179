/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Card from '../card'

import useArrayOfSize from '../../lib/hooks/use-array-of-size'
import { renderHTML } from '../../lib/helpers'

export const QuoteText = (props) => {
  const { quote, stars: _stars, by, color, hasBgColor, ...otherProps } = props

  const stars = useArrayOfSize(parseInt(_stars), String.fromCharCode(9733))
  const hasStars = stars && stars.length > 0

  const txtColor = hasBgColor ? 'black' : 'white'
  const bigQuote = quote && quote.length < 80

  const quoteSize = ['108px', '148px']

  return (
    <div
      sx={{
        pl: ['48px', '64px'],
        color: txtColor,
      }}
      {...otherProps}
    >
      {hasStars ? (
        <div
          sx={{
            variant: 'text.regular_36',
            fontSize: ['36', '48'],
            pb: quote && bigQuote ? 3 : quote ? 5 : null,
            color: color && !hasBgColor ? color : null,
          }}
        >
          {stars.join('')}
        </div>
      ) : null}
      {quote ? (
        <div
          sx={{
            position: 'relative',
            variant: bigQuote ? 'text.regular_36' : 'text.regular_25',

            '&:before': {
              variant: 'text.mono',
              fontSize: quoteSize,
              lineHeight: quoteSize,
              content: '"“"',
              position: 'absolute',
              left: ['-64px', '-89px'],
              top: bigQuote ? ['-20px', '-25px'] : ['-20px', '-32px'],
              color: hasBgColor ? null : 'grey2',
              opacity: hasBgColor ? 0.6 : 1,
            },
          }}
        >
          {renderHTML(quote)}
        </div>
      ) : null}
      {by ? (
        <div
          sx={{
            variant: 'text.mono',
            color: txtColor,
            pt: hasStars || quote ? [5, 7] : null,
          }}
        >
          {by}
        </div>
      ) : null}
    </div>
  )
}

const Quote = (props) => {
  const { quote, stars, by, color, ...otherProps } = props

  return (
    <Card expandPadding={true} bgColor={color}>
      <QuoteText
        quote={quote}
        stars={stars}
        by={by}
        color={color}
        hasBgColor={!!color}
      />
    </Card>
  )
}

export default Quote
